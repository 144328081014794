import React, {useState, useEffect} from 'react';
import * as Scroll from 'react-scroll';
import './styles/App.css';
import HomeImage from './assets/images/home.jpg';
import LeavesImage from './assets/images/leaves.png';
import TitleText from './components/TitleText';
import RocksImage from './assets/images/rocks.png';
import BibleImage from './assets/images/bible.png';
import MailingImage from './assets/images/mailing.png';
import OMSLogo from './assets/images/oms.png';
import { useMediaQuery } from 'react-responsive';
import { Parallax } from 'react-scroll-parallax';
import useWindowDimensions from './hooks/useWindowDimensions';
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  TwitterShareButton,
  EmailIcon,
} from "react-share";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

function App() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isSmallComputer = useMediaQuery({ query: '(max-width: 1420px'});
  const shouldShowScroll = useMediaQuery({ query: '(min-height:700px) and (max-height: 800px)'})
  const isReallyBigComputer = useMediaQuery({ query: '(min-height: 1215px)'})
  const { height, width } = useWindowDimensions();
  let scroll = Scroll.animateScroll;
  const [email, setEmail] = useState('');
  const [closingPrayer, setClosingPrayer] = useState([]);
  const [openingPrayer, setOpeningPrayer] = useState([]);
  const [scripture, setScripture] = useState('');
  const [scriptureSource, setScriptureSource] = useState('');
  const [bibleImageSource, setBibleImageSource] = useState(0);
  const [homeImageSource, setHomeImageSource] = useState(0);
  const [scriptureImageSource, setScriptureImageSource] = useState(0);


  const shareUrl = "https://freedailyprayers.com";
  const title = "See Today's Free Daily Prayer";


  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);
    fetch(`https://api.octtone.com/dailyAffirmationWeb/submit-email/${email}`)
      .then((res) => res.json())
      .then((result) => {
        document.getElementById("input_email").value = ""
        console.log(result);
        setEmail('');
      })
    }

  const scrollDown = () => {
    scroll.scrollTo(700);
  }

  function splitString(str) {
    let arr = str.split(/\r?\n/);
    let retArr = arr.filter(function (el) {
      return el != null;
    });

    return retArr;
  }



  useEffect(() => {
    fetch('https://api.octtone.com/dailyAffirmationWeb/getAffirmationEvening')
      .then(res => res.json())
      .then((result) => {
        let cPrayer = splitString(result.closingPrayer);
        setClosingPrayer(cPrayer);
        let oPrayer = splitString(result.openingPrayer);
        setOpeningPrayer(oPrayer);
        setScripture(result.scriture[0][0])
        setScriptureSource(result.scriture[0][1]);
        setBibleImageSource(result.image1);
        setHomeImageSource(result.image2);
        setScriptureImageSource(result.image3)

      })
  }, [])

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  let d = new Date();
  let month = months[d.getMonth()];
  let day = d.getDate();
  let dayOfWeek = daysOfWeek[d.getDay()]
  return (
    <div className="Main-Container">
      {!isTabletOrMobile ? (<Parallax
        y={[-20, 0]}
        className="border bottom_clip splash_image"
      >
        <img
          alt="Home Image"
          src={'https://d2g27qhphzrmc0.cloudfront.net/freeDailyPrayersHeaderImages/' + homeImageSource + `.jpg?${new Date().getTime()}`}
          className="home_image"
        />
      </Parallax>) : (      <div
        className="border bottom_clip splash_image"
      >
        <img
          alt="Home Image"
          src={'https://d2g27qhphzrmc0.cloudfront.net/freeDailyPrayersHeaderImages/' + homeImageSource + `.jpg?${new Date().getTime()}`}
          className="home_image"
        />
      </div>)}

      {!isTabletOrMobile && 
      <>     
       <div 
       className="date_container" style={{
        position: 'absolute', top: 70, left: 14, paddingLeft: 40, marginTop: 30, zIndex: 30, width: 200, height: 200,
      }}
      >
        <h1 className="dateText">{`Good ${ new Date().getHours() > 16 ? 'Evening' : `Morning`}`}</h1>
       </div>
        <div className="center_flex top_image_container">
          <img alt="Sun Image" className="sun_image" src={new Date().getHours() > 16 ? require('./assets/svg/moon1.png') : require('./assets/images/sun.png')} style={{color: 'white', width: 125, height: 'auto'}}/>
          <p style={{ color: 'white', fontSize: 30, zIndex: 30,marginTop: 20, fontWeight: 300 }}>{`${dayOfWeek}, ${month} ${day}`}</p>
        </div>
      </>
      }
      {isTabletOrMobile && (
        <>
          <div className="center_flex" style={{ width: '100%', height: 'auto', position: 'absolute', paddingTop: 20}}>
            <img alt="Sun" className="sun_image" src={new Date().getHours() > 16 ? require('./assets/svg/moon1.png') : require('./assets/images/sun.png')} style={{width: 125, height: 'auto'}}/>
            <h1 style={{fontSize: 50}}>{`Good ${ new Date().getHours() > 16 ? 'Evening' : `Morning`}`}</h1>
            <p style={{ color: 'white', fontSize: 30, zIndex: 30,marginTop: -30, fontWeight: 300 }}>{`${dayOfWeek}, ${month} ${day}`}</p>
          </div>
        </>
      )}

        {shouldShowScroll && (      
      <div id="scroll_indicator" className="center_flex" style={{
        position: 'absolute', bottom: 200, margin: '0 auto',
        fontWeight: 300, zIndex: 30, width: '100%', height: 200,
      }}
      >
        <p style={{ color: 'white', fontSize: 30, zIndex: 30,marginTop: 200, fontWeight: 300 }}>{`Scroll Down To Start Today’s ${new Date().getHours() > 16 ? 'Evening' : `Morning`} Prayer`}</p>
        <i className="gg-chevron-down" onClick={scrollDown}></i>
      </div>)}



      <div
        id="OpeningPrayer"
        style={{
          width: '100%',
          height: 'auto',
          // padding: '0 20px 0 20px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          color: 'black',
        }}
      >
        <TitleText text={"Opening Prayer"} />
        <div className="opening_container">
          {openingPrayer.map((item) => (<p>{item}</p>))}
          {/* <p>Eternal Lord God you hold all should in life:</p>
          <p>Give your whole church in paradise and on earth your light and your peace
          </p> 
          <p>And grant that we following the good examples of those who have served you here and are now at rest may at last enter with them into your unending joy;</p>
          <p>Through Jesus Christ our Lord who lives and reigns with you in the unity of the Holy Spirit one God now and forever 
          </p>
          <i>Amen</i> */}
        </div>

      </div>

      <div id="Scripture" style={{width: '100%'}} className="center_flex">
        <div
          y={[-60, 0]}
          className="top_bottom_clip abcf"
          // style={{
          //   height: '700px', width: '100%', position: 'relative', overflow: 'hidden',
          // }}

        >
          <img
          alt="Scripture"
          className="scripture_image"
          src={'https://d2g27qhphzrmc0.cloudfront.net/freeDailyPrayersHeaderImages/' + scriptureImageSource + '.jpg'}
          style={{
              height: 'auto', width: '100%',
              
            }}
          />
        <div className="border center_absolute scripture_text_container" style={{
          alignSelf: 'center',
        position: 'absolute', top: 10, marginTop: 10, zIndex: 30, width: '20%', height: 500,
        lineHeight: 2,
        fontWeight: 300,
        paddingTop: 20,
      }}
      > 
            <TitleText text={"Scripture"} white/>
            <p>{scripture}</p>
            <i style={{fontWeight: 600}}>{scriptureSource}</i>
      </div>
        </div>

        <TitleText text="Daily Bible Image" />
        <div className="opening_prayer border center_flex abcdefg" style={{height: 500, width: 400}}>
          <img alt="Verse of the Day" src={'https://d2g27qhphzrmc0.cloudfront.net/verseOfTheDayImages/' + bibleImageSource + '.png'}
 style={{borderRadius: 30, height: 'auto', width: "90%"}} />
          <div className="center_flex border" style={{flexDirection: 'row', width: 300, height: 50, justifyContent: 'space-between', marginTop: '30px'}} >
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={48} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
            <LinkedinIcon size={48} round />
          </LinkedinShareButton>
          <PinterestShareButton
            url={'https://omysoulapp.com'}
            media='../static/images/leaves.png'
            className="Demo__some-network__share-button"
          >
            <PinterestIcon size={48} round />
          </PinterestShareButton>
          </div>
        </div>
      </div>

      <div id="closing_prayer">
        <div
          className="top_bottom_clip"
          style={{
            height: isReallyBigComputer ? height * 1.56 : '1400px', width: '100%', position: 'relative', overflow: 'hidden',
          }}
        >
         {isTabletOrMobile || isSmallComputer ? <img
            alt=""
            className="lords_image"
            src={LeavesImage}
            style={{
              height: 'auto', width: '190%',
            }}
          /> : <img
          alt=""
          className="lords_image"
          src={LeavesImage}
          style={{
            height: 'auto', width: '200%',
          }}
        />} 
        <div className="border center_absolute lords_text" style={{
          alignSelf: 'center',
        position: 'absolute', top: isTabletOrMobile ? 50 : isReallyBigComputer ? 90 : 50, marginTop: 20, zIndex: 30, width: isTabletOrMobile ? '100%' : '100%', height: 600,
        paddingTop: 50,
      }}
      > 
            <TitleText text={"The Lord's Prayer"} white/>
              <p>Our Father which art in heaven,</p>
              <p>Hallowed be thy name.</p>
              <p>Thy kingdom come,</p>
              <p>Thy will be done in earth,</p>
              <p>as it is in heaven.</p>
              <p>Give us this day our daily bread.</p>
              <p>And forgive us our debts,</p>
              <p>as we forgive our debtors.</p>
              <p>And lead us not into temptation,</p>
              <p>but deliver us from evil:</p>
              <p>For thine is the kingdom,</p>
              <p>and the power, and the glory,</p>
              <p>for ever. Amen.</p>
            <i>Amen</i>
      </div>
        </div>
        <div style={{
          position: 'absolute', top: 10, left: 14, paddingLeft: 40, marginTop: 30, zIndex: 30, width: 200, height: 200,
        }}
        >
        </div>

        <div
          id="Closing Prayer"
          className="closing_prayer_container"
        >
          <TitleText text={"Closing Prayer"} />
          <div className="opening_container">
            <p>Eternal Lord God you hold all should in life:</p>
            <p>Give your whole church in paradise and on earth your light and your peace
            </p> 
            <p>And grant that we following the good examples of those who have served you here and are now at rest may at last enter with them into your unending joy;</p>
            <p>Through Jesus Christ our Lord who lives and reigns with you in the unity of the Holy Spirit one God now and forever 
            </p>
            <i>Amen</i>
          </div>

        </div>
      </div>
      <div id="mailing_list">
        <div
          className="bottom_container center_flex"
        >
          <img
            alt=""
            src={MailingImage}
            className="mailing_image"
          />
        {!isTabletOrMobile && <div className="border center_absolute center_flex image_email_container" style={{
          alignSelf: 'center',
        position: 'absolute', top: 10,zIndex: 30, width: 800, height: 500,
        paddingTop: 50,
      }}
      > 
            <h2>{"Start Getting Free Prayers Every Day"}</h2>
            <p style={{marginTop: -25, fontSize: 30}}>Join Our Free Mailing List</p>
            <div id="input_container" className="center_flex_row">
              <img alt="" src={require('./assets/icons/email.png')} style={{height: 25, width: 'auto'}}/>
              <input id="input_email" style={{width: '50%', height: 50, borderRadius: 30}} placeholder="email" type="text" onChange={handleChange}/>
            </div>

            <button onClick={handleSubmit} className="blue_button">Join Now!</button>
      </div>}
      {isTabletOrMobile && (
        <>
          <div className="center_flex" style={{ width: '100%', height: 'auto', position: 'absolute', paddingBottom: 30}}>
            <h2 style={{textAlign: 'center', marginTop: 50}}>{"Start Getting Free Prayers Every Day"}</h2>
            <p style={{fontSize: 30, textAlign: 'center', marginTop: -20}}>Join Our Free Mailing List</p>
            <div id="input_container" className="center_flex_row" style={{marginTop: -30}}>
              <img alt="" src={require('./assets/icons/email.png')} style={{position: 'absolute', left: 15, height: 25, width: 'auto'}}/>
              <input id="input_email" style={{width: '98%', height: 50, borderRadius: 30}} placeholder="email" type="text" onChange={handleChange}/>
            </div>
            <button style={{marginTop: 20}} onClick={handleSubmit} className="blue_button">Join Now!</button>
          </div>
        </>
      )}
        </div>
      </div>
      <div id="oms">
        <div className="bottom_container center_flex_row" style={{paddingBottom: 100}}>
          <div className="border oms_plug center_flex">
            <div style={{height: 'auto', textAlign: 'center'}}>
              <p style={{fontWeight: 200, marginTop: 20, color: 'black', marginBottom: 0}}>Content Provided By</p>
              <h2 style={{color: 'black', marginTop: 5}}>The O My Soul App</h2>
            </div>

            <img alt="O My Soul Logo" className="oms_image_logo" src={OMSLogo}style={{width: '25%', height: 'auto', paddingBottom: 30}}/>
            <div className="center_flex_row apple_badge" style={{width: '90%'}}>
              <img alt="AppStore Link" src={require('./assets/svg/apple_badge.svg')} style={{cursor: 'pointer'}} onClick={() => window.open('https://apps.apple.com/us/app/o-my-soul-christian-meditation/id1493998000')} className="gray_div" />
            </div>
          </div>
          <div className="border oms_image_container center_flex" style={{overflow: 'hidden'}}>
            <img alt="O My Soul App" className="bottom_iphone_image" style={{width: 'auto', height: '90%'}} src={`https://d2g27qhphzrmc0.cloudfront.net/FreeDailyPrayerWebAssets/${'iphone_mockup.png'}`}/>
          </div>
          <div className="center_flex_row apple_badge_mobile" style={{width: '90%', display: 'none'}}>
              <img alt="AppStore Link" src={require('./assets/svg/apple_badge.svg')} style={{cursor: 'pointer'}} onClick={() => window.open('https://apps.apple.com/us/app/o-my-soul-christian-meditation/id1493998000')} className="gray_div " />
            </div>
        </div>
      </div>

    </div>
  );
}

export default App;